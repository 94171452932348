import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Home.css';
import logo from './media/logo.png';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import Switch from "react-switch";
import "./collapsible.scss"; 
import Collapsible from 'react-collapsible';
import whitepaper from './media/whitepaper.png';
import whitepaperHover from './media/whitepaper_hover.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import scale from './media/scale.png'
import headshot from './media/headshot3-01.png';




class Home extends React.Component { 

  constructor() {
    super();
    this.state = { 
      mobile: false,
      checked: false,
      whitepaperImg: whitepaper,
      type: "consumer",
      typeChecked: false,
      email: "",
      wallet: ""
    };

    this.handleChange = this.handleChange.bind(this);

    
    
  }

  handleChange(checked) {
    this.setState({ checked });
  }



  
 
  componentDidMount()
  {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({mobile: true});
    }  
    
  }


  componentDidUpdate( )
  { 

  }
  
  render()
  {  

    return (
      <div className="pageContainer">  
        <div className="trueContainer">
          <div className='group0'>
            <img src={logo} className="logo"></img>
            <img src={headshot} style={{width: '80%', border: '.3rem solid black', borderRadius: '2rem', marginTop: '2em'}}></img>
                  <br></br>
                  <h2 style={{marginTop: '1em', marginBottom: '2em'}}><a href="mailto:fallon@fallonzirpoli.com" style={{color: 'black'}}>fallon@fallonzirpoli.com</a><br></br><a href="tel:+19545342270" style={{color: 'black'}}>954-534-2270</a> </h2>
            
            <h1 style={{marginBottom: '.2rem'}}><b>Mission</b></h1>
            <h2><i>The mission of Fallon Zirpoli P.A. is to treat each and every individual accused of a crime with the necessary individualized attention and preparation to protect his or her liberty, livelihood, and freedom.</i></h2>
            <img src={scale} className="logo"></img>
            <br></br>
            <br></br>
          </div>  
          <div className='twoGroupContainer'>
            <div className='group1'>
              <div>
                <h1 style={{marginBottom: '.2rem'}}><b>Areas of Practice</b></h1>
                <h2 style={{marginTop: '.75em'}}>Domestic Violence</h2>
                <h2 style={{marginTop: '.75em'}}>Drug Charges</h2>
                <h2 style={{marginTop: '.75em'}}>DUI</h2>
                <h2 style={{marginTop: '.75em'}}>Federal Crimes</h2>
                <h2 style={{marginTop: '.75em'}}>Felonies &#38; Misdemeanors</h2>
                <h2 style={{marginTop: '.75em'}}>Internet Crimes</h2>
                <h2 style={{marginTop: '.75em'}}>Sex Crimes</h2>
                <h2 style={{marginTop: '.75em'}}>Violent Crimes</h2>              
              </div>
              <div>
                <h1 style={{marginTop: '1rem'}}><b>Ask a Question</b></h1>
                <h5 style={{marginTop: '0', marginBottom: '1rem'}} ><b>your confidentiality is ensured</b></h5>
                <form action="/" method='POST' name='outreach' id="form"  netlify>
                <input type="hidden" name="form-name" value="outreach" />
                  <textarea placeholder='Fallon, what do I do if I...'  style={{width: '80%', height: '30vh', border: '.3rem solid black', borderRadius: '2rem', color: 'black', padding: '.75rem'}} id="question" name="question" required="required">

                  </textarea>
                  <br></br>
                  <button style={{marginTop: '1rem',minWidth: '30%', width: 'auto', border: '.3rem solid black', borderRadius: '2rem', color: 'black', padding: '.75rem', backgroundColor: 'transparent', fontSize: '1.2em', fontWeight: 'bold'}}><b>Submit</b></button>
      
                </form>
              </div>

              
            </div>
       

            <div className='group2'>
                <div style={{marginTop: '2em'}}>
                  
               
                  
                    
                </div>

              </div>

            

           
          </div>
          <div className='group3'>
              <div style={{marginTop: '0rem'}}>
                <Collapsible trigger="Biography">
                 <h2 style={{textAlign: 'left', marginTop: '1rem'}}>
                  Fallon Zirpoli practices exclusively in the area of criminal law. Fallon has honed her trial skills with a reputation for being a compassionate and fierce trial attorney.  
                  <br></br>
                  <br></br>
                  She has handled a wide range of criminal matters including but not limited to, homicides, drug offenses, sex crimes, robbery, as well as juvenile crimes pending direct file to the adult system. 
                  <br></br>
                  <br></br>
                  After graduating from Florida State University with a Bachelor of Science in Political Science, Fallon returned to South Florida where she attended Nova Southeastern University College of Law in 2012 and obtained her Juris Doctorate as a recipient of the Dean’s Merit Scholarship. 
                  <br></br>
                  <br></br>
                  In law school, Fallon served as an editor on Nova Law Review as well as a certified legal intern for her school’s Children and Families Law clinic, providing legal assistance to economically disadvantaged individuals. 
                  <br></br>
                  <br></br>
                  Fallon then began her legal career as an Assistant Public Defender for the Miami-Dade County Public Defender’s office for five years, where she continued her dedication to serving her community.  
                  <br></br>
                  <br></br>
                  As an Assistant Public Defender, Fallon gained extensive litigation and trial experience and now brings that same drive for justice and grit to private practice. 
                  <br></br>
                  <br></br>
                  Fallon was appointed and serves on The Florida Bar Juvenile Rules Committee on the delinquency subcommittee. 
                  <br></br>
                  <br></br>
                  Admissions:
                  <i>
                  <br></br>
                    Florida Bar United States District Court, Florida Southern District
                    <br></br>
                    United States District Court, Florida Middle District
                    <br></br>
                    United States District Court, Florida Northern District
                    <br></br>
                    United States Court of Appeals for the Eleventh Circuit
                    <br></br>
                    </i>
                 </h2>
                </Collapsible>
              </div>
            </div>

            <div className='group4' style={{marginTop: '2rem'}}>
              <h4><i>"The true measure of our character is how we treat the poor, the disfavored, the accused, the incarcerated, and the condemned."</i><br></br>-Bryan Stevenson</h4>
              <img src={logo} className="logo2" style={{marginTop: '1rem', marginBottom: '2rem'}}></img>
            </div>
        </div>


          



      </div>
      )
    
    
          
      
  }
}
export default Home;
